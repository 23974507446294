import React, { useState } from "react";
import { TabContent, TabPane } from "reactstrap";
import LoginDetails from "./LoginDetails";
import RestaurantDetails from "./RestaurantDetails";
import ServiceDetails from "./ServiceDetails";
import TabHeaders from "./TabHeaders";
import { useFormik } from "formik";
import * as Yup from "yup";
import restaurantService from "../../../services/restaurant.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const TabsLinks = () => {
  const [activeTab, setActiveTab] = useState("1");
  const navigate = useNavigate();
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const restaurantDetailsValidation = useFormik({
    initialValues: {
      restaurantName: "",
      restaurantSlug: "",
      restaurantPhone: "",
      contactName: "",
      contactEmail: "",
      street: "",
      zone: "",
      neighborhood: "",
      logoFile: null,
      backgroundImage: null,
    },
    validationSchema: Yup.object({
      restaurantName: Yup.string().required("Please enter Restaurant Name"),
      restaurantSlug: Yup.string().required("Please enter Restaurant Slug"),
      restaurantPhone: Yup.string().required("Please enter Restaurant Contact"),
      contactEmail: Yup.string().required("Please enter Contact Email"),
      contactName: Yup.string().required("Please enter Contact Name"),
      street: Yup.string().required("Please enter the Street"),
      state: Yup.string().required("Please enter the state"),
      zone: Yup.string().required("Please enter Zone"),
      neighborhood: Yup.string().required("Please select neighborhood"),
      logoFile: Yup.mixed().required("Please upload logo"),
      backgroundImage: Yup.mixed().required("Please upload background image"),
    }),
    onSubmit: (values) => {
      toggle("2");
    },
  });

  const serviceValidation = useFormik({
    initialValues: {
      cuisine: [],
      delivery: true,
      pickup: true,
      status: "",
    },
    validationSchema: Yup.object({
      cuisine: Yup.array()
        .min(1, "You must select at least one cuisine")
        .required("Please select cuisines"),
      deliver: Yup.boolean(),
      pickup: Yup.boolean(),
      status: Yup.string().required("Please select a status"),
    }),
    onSubmit: (values) => {
      console.log(values);
      console.log(
        "Selected Cuisines:",
        values.cuisine.map((c) => c.value)
      );
      toggle("3");
    },
  });

  const loginValidation = useFormik({
    initialValues: {
      password: "",
      commission: 0,
      lat: null,
      lng: null,
    },

    validationSchema: Yup.object({
      password: Yup.string().required("Please enter password"),
      commission: Yup.number()
        .max(100, "Enter a valid percentage")
        .required("Please enter commission"),
      lat: Yup.number().required(),
      lng: Yup.number().required(),
    }),
    onSubmit: async (values) => {
      console.log(values);
      console.log(restaurantDetailsValidation.values);
      console.log(serviceValidation.values);
      try {
        const resp = await restaurantService.addRestaurant(
          restaurantDetailsValidation,
          serviceValidation,
          values
        );
        if (resp.success) {
          navigate("/listRestaurants");
        }
      } catch (error) {
        console.log(error);

        toast.error("Error adding Restaurant!!");
      }
    },
  });

  const tabs = [
    <RestaurantDetails
      restaurantDetailsValidation={restaurantDetailsValidation}
    />,
    <ServiceDetails validation={serviceValidation} />,
    <LoginDetails validation={loginValidation} />,
  ];

  return (
    <div>
      <TabHeaders toggle={toggle} activeTab={activeTab} />
      <TabContent activeTab={activeTab}>
        {tabs.map((tab, index) => {
          return (
            <TabPane tabId={`${index + 1}`} key={index}>
              <div className="mt-2">{tab}</div>
            </TabPane>
          );
        })}
      </TabContent>
    </div>
  );
};

export default TabsLinks;
