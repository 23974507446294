import React, { useContext, useEffect } from "react";
import envConfig from "../utils/envConfig";
const AppContext = React.createContext(null);

const AppContextProvider = ({ children }) => {
  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const googleMapsScript = document.createElement("script");
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${envConfig.googleMapsKey}&libraries=places`;
      googleMapsScript.defer = true;
      document.body.appendChild(googleMapsScript);

      googleMapsScript.onload = () => {
        console.log("Google Maps script loaded successfully");
      };
    };

    loadGoogleMapsScript();
  }, []);

  const value = { test: "test" };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppContextProvider;

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("The Component should be in the AppContextProvider");
  }
  return context;
};
