import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import TabsLinks from "../common/Tabs/TabsLinks";
import { ToastContainer } from "react-toastify";

const AddRestaurants = () => {
  return (
    <div className="page-content">
      <Card>
        <CardBody>
          <CardTitle>Add Restaurant</CardTitle>
          <CardBody>
            <TabsLinks />
          </CardBody>
        </CardBody>
      </Card>
      <ToastContainer />
    </div>
  );
};

export default AddRestaurants;
