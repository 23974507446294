import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
const TabHeaders = ({ toggle, activeTab }) => {
  return (
    <Nav tabs>
      <NavItem className="pointer-cursor">
        <NavLink
          className={classnames({ active: activeTab === "1" })}
          onClick={() => toggle("1")}
        >
          Restaurant Details
        </NavLink>
      </NavItem>
      <NavItem className="pointer-cursor">
        <NavLink
          className={classnames({ active: activeTab === "2" })}
          onClick={() => toggle("2")}
        >
          Service Details
        </NavLink>
      </NavItem>
      <NavItem className="pointer-cursor">
        <NavLink
          className={classnames({ active: activeTab === "3" })}
          onClick={() => toggle("3")}
        >
          Login Details
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default TabHeaders;
