import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import user from "../../../assets/images/users/avatar-1.jpg";
import { useAuthContext } from "../../../context/AuthContextProvider";

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);

  const { logout, userDetails } = useAuthContext();
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-2">
            {userDetails.firstName}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem>
            <Link to={"/userProfile"}>
              <i className="ri-user-line align-middle me-2" />
              {props.t("Profile")}{" "}
            </Link>
          </DropdownItem>

          <div className="dropdown-divider" />
          <div onClick={logout} className="dropdown-item">
            <i className="ri-shut-down-line align-middle me-2 text-danger" />
            <span>{props.t("Logout")}</span>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(ProfileMenu);
