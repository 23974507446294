import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ProtectedRoutes from "../components/Authentication/ProtectedRoutes";
import Layout from "../components/Layout/Layout";
import { protectedRoutes, publicRoutes } from "./routes";
import My404Component from "../components/404Component";

const Index = () => {
  useEffect(() => {
    document.title = "Yobo Restro Admin Panel";
  }, []);
  return (
    <Routes>
      {protectedRoutes.map((route, index) => (
        <Route
          path={route.path}
          element={
            <ProtectedRoutes>
              <Layout>{route.component}</Layout>
            </ProtectedRoutes>
          }
          key={index}
        />
      ))}
      {publicRoutes.map((route, index) => (
        <Route path={route.path} element={route.component} key={index} />
      ))}
      <Route path="*" element={<My404Component />} />
      
    </Routes>
  );
};

export default Index;
