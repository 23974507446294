const Auth = {
  login: "/admin/login",
};

const User = {
  getDetails: "/admin/getUserDetails",
  updateDetails: "/admin/updateUserDetails",
  updatePassword: "/admin/updatePassword",
};

const Address = {
  getStateList: "/address/state",
  getZoneList: "/address/zone",
  getNeighborhoodList: "/address/subZone",
};

const Cuisine = {
  getCusisineList: "/details/cuisines",
  addCuisine: "/admin/addCuisine",
  getCuisines: "/admin/getCuisines",
  getActiveCuisines: "/admin/activeCuisines",
  getInactive: "/admin/inActiveCuisines",
  deleteCuisine: "/admin/deleteCuisine",
  searchCuisine: "/admin/searchCuisine",
  updateCuisineStatus:"/admin/updateCuisineStatus",
  getCuisineById:'/admin/getCuisine'
};

const Restaurant = {
  addRestaurant: "/admin/createRestaurant",
  getRestaurants: "/admin/getRestaurants",
  getRestaurantById: "/admin/getRestaurant/",
  searchRestaurants: "/admin/searchRestaurant",
  getInactiveRestaurants: "/admin/inactiveRestaurant",
  getActiveRestaurants: "/admin/activeRestaurants",
  updateRestaurantStatus: "/admin/updateRestaurantStatus",
};
const Services = {
  updatePickup: "/admin/updatePickup",
  updateDelivery: "/admin/updateDelivery",
  addSchedule: "/admin/addSchedule",
};
const Api = { Auth, User, Address, Cuisine, Restaurant, Services };
export default Api;
