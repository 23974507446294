import { GoogleMap, Marker } from "@react-google-maps/api";
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import userImage from "../../assets/images/users/avatar-1.jpg";
import restaurantService from "../../services/restaurant.service";
import FileHistory from "../common/FileHistory";
import Loader from "../common/Loader";
import FoodDetail from "./RestaurantDetail/FoodDetail";
import Settings from "./RestaurantDetail/Settings";

const mapContainerStyle = {
  width: "100%",
  height: "250px",
};

const ViewRestaurant = () => {
  const { id } = useParams();
  const [restaurantDetail, setRestaurantDetail] = useState(null);
  
  const mapRef = useRef(null);
  console.log(restaurantDetail);

  const tabs = [
    restaurantDetail && <FoodDetail cuisine={restaurantDetail.cuisine} />,
    <div>Hello There</div>,
    restaurantDetail && (
       <Settings
        delivery={restaurantDetail.delivery ? restaurantDetail.delivery : []}
        pickup={restaurantDetail.pickup ? restaurantDetail.pickup : []}
      />
    ),
  ];
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    const getRestaurantDetails = async () => {
      const res = await restaurantService.getRestaurantById(id);
      if (!res.success) toast.error("Error fetching restaurant details");
      console.log("Helo", res.data);
      setRestaurantDetail(res.data.data);
    };
    getRestaurantDetails();
  }, []);

  if (!restaurantDetail) return <Loader />;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card className="p-3">
            <FileHistory title="Dashboard" breadcrumbItem="Restaurant Detail" />
            <CardBody>
              <Row>
                <Col md="5" className=" ">
                  <div className="me-3 mb-2 d-flex gap-3">
                    <img
                      src={userImage}
                      alt=""
                      className="avatar-lg rounded-circle img-thumbnail"
                    />
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h3>{restaurantDetail.restaurant_name}</h3>
                        <p className="mb-1">{restaurantDetail.contact_email}</p>
                        <p className="mb-0">Id no: # {restaurantDetail.id}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    Neighborhood: {restaurantDetail.neighborhood}
                  </div>

                  <div className="mb-2">
                    Phone Number: {restaurantDetail.restaurant_phone}
                  </div>
                  <div className="mb-2">
                    Commission: {Number(restaurantDetail.commission)}%
                  </div>
                  <div className="mb-2">
                    Status:{" "}
                    <span className="text-capitalize">
                      {restaurantDetail.status}
                    </span>
                  </div>
                </Col>
                <Col md="7" className=" d-none d-lg-block">
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={{
                      lat: Number(restaurantDetail.latitude),
                      lng: Number(restaurantDetail.longitude),
                    }}
                    zoom={15}
                    onClick={() => {}}
                    onLoad={(map) => (mapRef.current = map)}
                    className="m-3"
                  >
                    <Marker
                      position={{
                        lat: Number(restaurantDetail.latitude),
                        lng: Number(restaurantDetail.longitude),
                      }}
                    />
                  </GoogleMap>
                </Col>
                <Col md="7" className="d-lg-none ">
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={{
                      lat: Number(restaurantDetail.latitude),
                      lng: Number(restaurantDetail.longitude),
                    }}
                    zoom={15}
                    onClick={() => {}}
                    onLoad={(map) => (mapRef.current = map)}
                    className="m-3"
                  >
                    <Marker
                      position={{
                        lat: Number(restaurantDetail.latitude),
                        lng: Number(restaurantDetail.longitude),
                      }}
                    />
                  </GoogleMap>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>

        <Container fluid>
          <Card>
            <CardBody>
              <h4 className="mb-0 font-size-18">Services</h4>
              <Nav tabs className="d-flex justify-content-end">
                <NavItem className="pointer-cursor">
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => toggle("1")}
                  >
                    Cuisines
                  </NavLink>
                </NavItem>
                <NavItem className="pointer-cursor">
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => toggle("2")}
                  >
                    Food Details
                  </NavLink>
                </NavItem>
                <NavItem className="pointer-cursor">
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => toggle("3")}
                  >
                    Settings
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                {tabs.map((tab, index) => {
                  return (
                    <TabPane tabId={`${index + 1}`} key={index}>
                      <div className="mt-2">{tab}</div>
                    </TabPane>
                  );
                })}
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default ViewRestaurant;
