import userApi from "../api/userApi";
import Api from "../constants/Api";
import { uploadToS3 } from "./s3.service";

const addCuisine = async (data) => {
  try {
    const resp = await userApi.post(Api.Cuisine.addCuisine, {
      name: data.name,
      image: { name: data.image.name, type: data.image.type },
      status: data.status,
    });
    await uploadToS3(resp.data.data.url, data.image);
    return { success: true, data: resp.data.message };
  } catch (error) {
    console.log(error);
    return { success: false, data: error };
  }
};

const getCusisineList = async (pageNumber) => {
  try {
    const resp = await userApi.get(
      Api.Cuisine.getCuisines + "?pageNumber=" + pageNumber
    );
    return { success: true, data: resp.data };
  } catch (error) {
    console.log(error);
    return { success: false, data: error };
  }
};
const getInactiveCusisineList = async (pageNumber) => {
  try {
    const resp = await userApi.get(
      Api.Cuisine.getInactive + "?pageNumber=" + pageNumber
    );
    return { success: true, data: resp.data };
  } catch (error) {
    console.log(error);
    return { success: false, data: error };
  }
};
const getActiveCusisineList = async (pageNumber) => {
  try {
    const resp = await userApi.get(
      Api.Cuisine.getActiveCuisines + "?pageNumber=" + pageNumber
    );
    return { success: true, data: resp.data };
  } catch (error) {
    console.log(error);
    return { success: false, data: error };
  }
};
const deleteCuisine = async (id) => {
  try {
    const resp = await userApi.delete(Api.Cuisine.deleteCuisine + "/" + id);
    return { success: true, data: resp.data };
  } catch (error) {
    console.log(error);
    return { success: false, data: error };
  }
};

const searchCuisine = async (query, pageNumber) => {
  try {
    const resp = await userApi.get(
      Api.Cuisine.searchCuisine + "?q=" + query + "&pageNumber=" + pageNumber
    );
    return { success: true, data: resp };
  } catch (error) {
    return { success: false, data: error };
  }
};

const updateCuisineStatus = async (id, status) => {
  try {
    const resp = await userApi.put(Api.Cuisine.updateCuisineStatus, {
      id,
      status,
    });
    return { success: true, data: resp };
  } catch (error) {
    return { success: false, data: error };
  }
};

const getCuisineById = async (id) => {
  try {
    const resp = await userApi.get(Api.Cuisine.getCuisineById + "/" + id);
    return { success: true, data: resp };
  } catch (error) {
    return { success: false, data: error };
  }
};

const cuisineService = {
  addCuisine,
  deleteCuisine,
  getCusisineList,
  searchCuisine,
  getInactiveCusisineList,
  getActiveCusisineList,
  updateCuisineStatus,
  getCuisineById,
};
export default cuisineService;
