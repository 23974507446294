import React from "react";
import { Spinner } from "reactstrap";

const Loader = () => {
  return (
    <div className="page-content">
      <div className="w-100 h-100 d-flex align-items-center justify-content-center">
        <Spinner color="success" />
      </div>
    </div>
  );
};

export default Loader;
