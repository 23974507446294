import { Navigate } from "react-router-dom";
import Login from "../components/Authentication/Login";
import Dashboard from "../components/Dashboard";
import UserProfile from "../components/UserProfile/UserProfile";
import AddRestaurants from "../components/Restaurants/AddRestaurants";
import ListRestaurants from "../components/Restaurants/ListRestaurants";
import ViewRestaurant from "../components/Restaurants/ViewRestaurant";
import AddCuisine from "../components/Category/Cuisines/AddCuisine";
import ListCuisine from "../components/Category/Cuisines/ListCuisine";
import UpdateCuisine from "../components/Category/Cuisines/UpdateCuisine";

const protectedRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/userProfile",
    component: <UserProfile />,
  },
  {
    path: "/addRestaurants",
    component: <AddRestaurants />,
  },
  {
    path: "/listRestaurants",
    component: <ListRestaurants />,
  },
  {
    path: "/addCuisine",
    component: <AddCuisine />,
  },
  {
    path: "/listCuisines",
    component: <ListCuisine />,
  },
  {
    path: "/restaurant/view/:id",
    component: <ViewRestaurant />,
  },
  {
    path: "/cuisine/view/:id",
    component: <UpdateCuisine />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];
const publicRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
];

export { protectedRoutes, publicRoutes };
