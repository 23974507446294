import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import restaurantService from "../../services/restaurant.service";
import { toast } from "react-toastify";

const StatusToggle = ({ id, merchant_id, status, list }) => {
  const [statusToggle, setStatusToggle] = useState(status);

  const updateStatus = async () => {
    setStatusToggle(!statusToggle);
    const resp = await restaurantService.updateRestaurantStatus(
      id,
      merchant_id,
      !statusToggle
    );
    if (!resp.success) {
      toast.error("Error updating Status ");
    }
  };

  useEffect(() => {
    setStatusToggle(status);
  }, [status]);

  return (
    <FormGroup switch>
      <Input
        type="switch"
        role="switch"
        checked={statusToggle}
        onChange={updateStatus}
      />
    </FormGroup>
  );
};

export default StatusToggle;
