import React from "react";
import { Badge, Label } from "reactstrap";

const FoodDetail = ({ cuisine }) => {
  if (cuisine.length == 0 || cuisine.includes(null))
    return <h6>No cuisines </h6>;
  return (
    <React.Fragment>
      <Label>
        Cuisine
      </Label>
      <div>
        {cuisine.map((cuisine, index) => {
          return (
            <Badge key={index} className="mx-2 pt-2 px-2" color="success">
              <h6>{cuisine.cuisine_name}</h6>
            </Badge>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default FoodDetail;
