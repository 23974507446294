import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import useDidUpdate from "../../hooks/useDidUpdate";
import restaurantService from "../../services/restaurant.service";
import Loader from "../common/Loader";
import StatusToggle from "./StatusToggle";

const ListRestaurants = () => {
  const [restaurantList, setRestaurantList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchPageNumber, setSearchPageNumber] = useState(1);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [totalNumber, setTotalNumber] = useState(0);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("active");

  const getActiveRestaurants = async () => {
    setFilter("active");
    setSearchEnabled(false);
    const resp = await restaurantService.getActiveRestaurants(pageNumber);
    if (resp.success) {
      console.log("Active", resp);
      setTotalNumber(resp.data.data.total);
      setRestaurantList(resp.data.data.resp);
    } else {
      toast.error("Error fetching restaurants!!");
    }
  };

  const getInactiveRestaurants = async () => {
    setFilter("inactive");
    setSearchEnabled(false);
    const resp = await restaurantService.getInactiveRestaurants(pageNumber);
    if (resp.success) {
      console.log("Inactive", resp);
      setTotalNumber(resp.data.data.total);
      setRestaurantList(resp.data.data.resp);
    } else {
      toast.error("Error fetching restaurants!!");
    }
  };
  const getRestaurantList = async () => {
    setFilter("all");
    setSearchEnabled(false);
    const resp = await restaurantService.getRestaurants(pageNumber);

    if (resp.success) {
      setTotalNumber(resp.data.data.total);
      setRestaurantList(resp.data.data.resp);
    } else {
      toast.error("Error fetching restaurants!!");
    }
  };
  useEffect(() => {
    switch (filter) {
      case "active":
        getActiveRestaurants();
        break;
      case "inactive":
        getInactiveRestaurants();
        break;
      default:
        getRestaurantList();
        break;
    }
  }, [pageNumber]);

  useDidUpdate(() => {
    searchResult(searchTerm);
  }, [searchPageNumber]);

  const nextPage = () => {
    if (searchEnabled) {
      if (searchPageNumber < Math.ceil(totalNumber / 10)) {
        setSearchPageNumber(searchPageNumber + 1);
      }
    } else {
      if (pageNumber < Math.ceil(totalNumber / 10)) {
        setPageNumber(pageNumber + 1);
      }
    }
  };
  const prevPage = () => {
    if (searchEnabled) {
      if (searchPageNumber > 1) {
        setSearchPageNumber(searchPageNumber - 1);
      }
    } else {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1);
      }
    }
  };

  const searchResult = async (searchTerm) => {
    const res = await restaurantService.searchRestaurants(
      searchTerm,
      searchPageNumber
    );

    if (res.success) {
      setSearchEnabled(true);
      setRestaurantList(res.data.data.resp);
      setTotalNumber(res.data.data.total);
    } else {
      toast.error("Error fetching restaurants!!");
    }
  };
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value === "") {
      getRestaurantList();
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  if (restaurantList == 0) {
    return (
      <div className="page-content">
        <Loader />
      </div>
    );
  }

  return (
    <div className="page-content">
      <div className="d-flex justify-content-between align-items-center">
        <InputGroup>
          <Input
            type="text"
            placeholder="Search Restaurant"
            onChange={handleSearchChange}
            value={searchTerm}
            onKeyDown={(e) => {
              e.key === "Enter" && searchResult(e.target.value);
            }}
          />

          <Button color="primary" onClick={() => searchResult(searchTerm)}>
            Search
          </Button>
        </InputGroup>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle caret className="mx-2">
            <i className="mdi mdi-filter"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                getActiveRestaurants();
              }}
            >
              Active
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                getInactiveRestaurants();
              }}
            >
              Inactive
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                getRestaurantList();
              }}
            >
              All
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="my-2">{totalNumber} results found</div>
      <div className="table-responsive table-card mt-3 mb-1">
        <table className="table align-middle table-nowrap" id="customerTable">
          <thead className="table-light">
            <tr>
              <th scope="col" style={{ width: "20px" }}>
                #ID
              </th>
              <th className="sort" data-sort="customer_name">
                Restaurant Name
              </th>
              <th className="sort" data-sort="email">
                Phone Number
              </th>
              <th className="sort" data-sort="phone">
                Neighborhood
              </th>
              <th className="sort" data-sort="action">
                Status
              </th>
              <th className="sort" data-sort="action">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="list form-check-all">
            {restaurantList.map((data, index) => (
              <tr key={index}>
                <td className="id">
                  {data.merchant_id == 0 ? data.id : data.merchant_id}
                </td>
                <td className="customer_name">{data.restaurant_name}</td>
                <td className="email">{data.restaurant_phone}</td>
                <td className="status">{data.neighborhood}</td>
                <td className="status">
                  <StatusToggle
                    id={data.id}
                    merchant_id={data.merchant_id}
                    status={data.status}
                    list={restaurantList}
                  />
                </td>

                <td>
                  <div className="d-flex gap-2">
                    <div className="edit">
                      <button
                        className="btn btn-sm btn-success edit-item-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#showModal"
                        onClick={() => {
                          navigate(`/restaurant/view/${data.id}`);
                        }}
                      >
                        <i className="mdi mdi-eye" />
                      </button>
                    </div>
                    {/* <div className="remove">
                      <button
                        className="btn btn-sm btn-danger remove-item-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteRecordModal"
                        onClick={() => {
                          navigate("/restaurant/edit", {
                            state: { id: data.id },
                          });
                        }}
                      >
                        <i className="mdi mdi-pencil" />
                      </button>
                    </div> */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <div>Page: {searchEnabled ? searchPageNumber : pageNumber}</div>
          <div>Total Pages: {Math.ceil(totalNumber / 10)}</div>
        </div>
        <Pagination>
          <PaginationItem>
            <PaginationLink previous onClick={prevPage} />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink next onClick={nextPage} />
          </PaginationItem>
        </Pagination>
      </div>
    </div>
  );
};

export default ListRestaurants;
