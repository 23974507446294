import { useFormik } from "formik";
import React, { useState } from "react";
import { Form } from "reactstrap";

const Schedule = () => {
  const [schedule, setSchedule] = useState([]);
  const validation = useFormik({
    initialValues: {
      schedule: [],
    },

    onSubmit: (values) => {
      console.log(values);
    },
  });
  return (
    <React.Fragment>
      <Form onSubmit={validation.handleSubmit}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Form>
    </React.Fragment>
  );
};

export default Schedule;
