import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import utilService from "../../../services/utils.service";

const ServiceDetails = ({ validation }) => {
  const [cuisineList, setCuisineList] = useState([]);

  useEffect(() => {
    const getCuisineList = async () => {
      const res = await utilService.getCuisineList();
      if (res.success) {
        setCuisineList(
          res.data.map((cuisine) => ({
            value: cuisine.cuisine_id,
            label: cuisine.cuisine_name,
          }))
        );
      } else {
        toast.error("Error fetching cuisine list");
      }
    };
    getCuisineList();
  }, []);

  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}
      >
        <div className=" mb-2 w-100">
          <Label for="cuisine">Cuisines</Label>
          <div
            className={`form-control ${
              validation.touched.cuisine && validation.errors.cuisine
                ? "is-invalid"
                : ""
            } w-100`}
          >
            <Select
              id="cuisine"
              name="cuisine"
              options={cuisineList}
              isMulti
              className="w-100"
              value={validation.values.cuisine
                .map((value) => {
                  const cuisine = cuisineList.find((c) => c.value === value);
                  return cuisine
                    ? { value: cuisine.value, label: cuisine.label }
                    : null;
                })
                .filter(Boolean)} // Filter out any null values
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions.map(
                  (option) => option.value
                ); // Extract only values
                validation.setFieldValue("cuisine", selectedValues);
              }}
              onBlur={() => validation.setFieldTouched("cuisine", true)}
              styles={{
                control: (provided) => ({
                  ...provided,
                  border:
                    validation.touched.cuisine && validation.errors.cuisine
                      ? "1px solid red"
                      : provided.border,
                }),
              }}
            />
          </div>
          {validation.touched.cuisine && validation.errors.cuisine ? (
            <FormFeedback className="d-block">
              {validation.errors.cuisine}
            </FormFeedback>
          ) : null}
        </div>
        <Label>Services Provided</Label>
        <div className="d-flex gap-3 mb-2">
          <FormGroup switch>
            <Input
              type="switch"
              name="delivery"
              role="switch"
              checked={validation.values.delivery}
              onChange={validation.handleChange}
            />
            <Label check>Delivery </Label>
          </FormGroup>
          <FormGroup switch>
            <Input
              type="switch"
              role="switch"
              name="pickup"
              checked={validation.values.pickup}
              onChange={validation.handleChange}
            />
            <Label check>Pickup </Label>
          </FormGroup>
          <Input type="hidden" />
        </div>
        <div className=" mb-2">
          <Label>Status</Label>
          <select
            className={`form-control ${
              validation.touched.status && validation.errors.status
                ? "is-invalid"
                : ""
            }`}
            name="status"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.status}
          >
            <option value={""}>Select Status</option>
            <option value={"pending"}>Pending for Approval</option>
            <option value={"active"}>Active</option>
            <option value={"suspended"}>Suspended</option>
            <option value={"blocked"}>Blocked</option>
            <option value={"expired"}>Expired</option>
          </select>
          {validation.touched.status && validation.errors.status ? (
            <FormFeedback type="invalid">
              {validation.errors.status}
            </FormFeedback>
          ) : null}
        </div>
        <Button type="submit" className="w-100 mt-3" color="success">
          Next
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default ServiceDetails;
