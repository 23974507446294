import userApi from "../api/userApi";
import Api from "../constants/Api";

const addRestaurant = async (
  restaurantDetails,
  serviceDetails,
  loginDetails
) => {
  console.log(restaurantDetails);
  const formData = new FormData();

  Object.entries(restaurantDetails.values).forEach(([key, value]) => {
    if (key !== "logoFile" && key !== "backgroundImage") {
      formData.append(key, value);
    } else if (key === "logoFile" || key === "backgroundImage") {
      formData.append(`${key}.name`, value.name);
      formData.append(`${key}.type`, value.type);
    }
  });

  Object.entries(serviceDetails.values).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        if (typeof v === "object" && v !== null) {
          formData.append(`${key}[]`, JSON.stringify(v));
        } else {
          formData.append(`${key}[]`, v);
        }
      });
    } else {
      formData.append(key, value);
    }
  });
  Object.entries(loginDetails).forEach(([key, value]) => {
    formData.append(key, value);
  });

  try {
    const resp = await userApi.post(Api.Restaurant.addRestaurant, formData);
    return { success: true, data: resp };
  } catch (error) {
    console.log(error);
    throw new Error({ success: false, data: error });
  }
};

const getRestaurants = async (pageNumber) => {
  try {
    const resp = await userApi.get(
      Api.Restaurant.getRestaurants + "?" + "pageNumber=" + pageNumber
    );
    return { success: true, data: resp };
  } catch (error) {
    console.log(error);
    return { success: false, data: error };
  }
};

const searchRestaurants = async (query, pageNumber) => {
  try {
    const resp = await userApi.get(
      Api.Restaurant.searchRestaurants +
        "?q=" +
        query +
        "&pageNumber=" +
        pageNumber
    );
    return { success: true, data: resp };
  } catch (error) {
    return { success: false, data: error };
  }
};

const getRestaurantById = async (id) => {
  try {
    console.log(Api.Restaurant.getRestaurantById + id);
    const resp = await userApi.get(Api.Restaurant.getRestaurantById + id);
    return { success: true, data: resp };
  } catch (error) {
    console.log(error);
    return { success: false, data: error };
  }
};

const updatePickup = async (pickup) => {
  try {
    const resp = await userApi.put(Api.Services.updatePickup, { pickup });
    return {
      success: true,
      data: resp,
      message: "Pickup updated successfully",
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      message: "Error Pickup not updated",
    };
  }
};
const updateDelivery = async (delivery) => {
  try {
    const resp = await userApi.put(Api.Services.updateDelivery, { delivery });
    return {
      success: true,
      data: resp,
      message: "Delivery updated successfully",
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      message: "Error Delivery not updated",
    };
  }
};

const getInactiveRestaurants = async (pageNumber) => {
  try {
    const resp = await userApi.get(
      Api.Restaurant.getInactiveRestaurants + "?" + "pageNumber=" + pageNumber
    );
    return { success: true, data: resp };
  } catch (error) {
    return { success: false, data: error };
  }
};
const getActiveRestaurants = async (pageNumber) => {
  try {
    const resp = await userApi.get(
      Api.Restaurant.getActiveRestaurants + "?" + "pageNumber=" + pageNumber
    );
    return { success: true, data: resp };
  } catch (error) {
    return { success: false, data: error };
  }
};

const updateRestaurantStatus = async (id, merchant_id, status) => {
  try {
    const resp = await userApi.put(Api.Restaurant.updateRestaurantStatus, {
      id,
      merchant_id,
      status,
    });
    return { success: true, data: resp };
  } catch (error) {
    return { success: false, data: error };
  }
};
const restaurantService = {
  addRestaurant,
  getRestaurants,
  getRestaurantById,
  updatePickup,
  updateDelivery,
  searchRestaurants,
  getInactiveRestaurants,
  getActiveRestaurants,
  updateRestaurantStatus,
};
export default restaurantService;
