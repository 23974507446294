import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import cuisineService from "../../../services/cuisine.service";
import { toast } from "react-toastify";
import AddCuisine from "./AddCuisine";

const UpdateCuisine = () => {
  const { id } = useParams();
  console.log(id);
  const [cuisineDetails, setCuisineDetails] = useState({});
  const navigate = useNavigate();
  const getCuisineDetails = async () => {
    const resp = await cuisineService.getCuisineById(id);
    if (resp.success) {
      console.log(resp);
      setCuisineDetails(resp.data);
    } else {
      toast.error("Error fetching cuisine details");
    }
  };

  

  useEffect(() => {
    getCuisineDetails();
  }, []);

  return <AddCuisine />;
};

export default UpdateCuisine;
