import React from "react";

import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

//import images
import logoLight from "../../assets/images/logo-light.png";
import logoSm from "../../assets/images/logo-sm.png";

import LanguageDropdown from "../common/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../common/TopbarDropdown/ProfileMenu";

const Header = (props) => {
  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box text-center">
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img
                    src={
                      "https://www.yoboresto.com/upload/1550741248-Logo-copie.PNG"
                    }
                    alt="logo-sm-light"
                    height="30"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src={
                      "https://www.yoboresto.com/upload/1550741248-Logo-copie.PNG"
                    }
                    alt="logo-light"
                    height="50"
                  />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              onClick={() => {
                tToggle();
              }}
            >
              <i className="ri-menu-2-line align-middle"></i>
            </button>
          </div>

          <div className="d-flex">
            <LanguageDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default withTranslation()(Header);
