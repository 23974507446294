import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext(null);

const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const [token, setToken] = useState("");
  //Flag to track the rememberMe checkbox at the login page
  const [rememberMe, setRememberMe] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });

  console.log(rememberMe);

  useEffect(() => {
    localStorage.setItem("rememberMe", rememberMe);
  }, [rememberMe]);

  useEffect(() => {
    const remember = localStorage.getItem("rememberMe");
    if (remember) setRememberMe(remember);
  }, []);

  useEffect(() => {
    if (token) localStorage.setItem("authToken", token);
  }, [token]);

  const logout = () => {
    localStorage.removeItem("authToken");
    setRememberMe(false);
    navigate("/login");
  };

  const values = {
    token,
    setToken,
    logout,
    userDetails,
    setUserDetails,
    rememberMe,
    setRememberMe,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("The component should be in Auth Context Provider");
  }
  return context;
};
