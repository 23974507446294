import userApi from "../api/userApi";
import Api from "../constants/Api";

const getStateList = async () => {
  try {
    const res = await userApi.get(Api.Address.getStateList);
    return { success: true, data: res.data };
  } catch (error) {
    return { success: false, data: error };
  }
};
const getZoneList = async (state) => {
  try {
    const res = await userApi.get(Api.Address.getZoneList + "?state=" + state);
    return { success: true, data: res.data };
  } catch (error) {
    return { success: false, data: error };
  }
};
const getNeighborhoodList = async (zoneId) => {
  try {
    const res = await userApi.get(
      Api.Address.getNeighborhoodList + "?zoneId=" + zoneId
    );
    return { success: true, data: res.data };
  } catch (error) {
    return { success: false, data: error };
  }
};

const addressService = { getStateList, getZoneList, getNeighborhoodList };

export default addressService;
