import { useFormik } from "formik";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormFeedback,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import cuisineService from "../../../services/cuisine.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddCuisine = ({data,title}) => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const validation = useFormik({
    initialValues: {
      image: null,
      name: "",
      status: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please enter Cuisine name"),
      image: Yup.mixed().required("Please Upload Cuisine Image"),
      status: Yup.boolean().required("Please Select a Status"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      setLoader(true);
      const resp = await cuisineService.addCuisine(values);
      if (resp.success) {
        toast.success(resp.message);
        setLoader(false);
        navigate("/listCuisines");
      } else {
        toast.error("Something Went wrong, Please try again later");
      }
    },
  });

  const onDrop = (acceptedFiles) => {
    validation.setFieldValue("image", acceptedFiles[0]);
  };

  const { getInputProps, getRootProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        "image/*": [],
      },
    });
  return (
    <div className="page-content">
      <Card>
        <CardTitle className="p-3">Add Cuisine</CardTitle>
        <CardBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}
          >
            <div>
              <Label>Name</Label>
              <Input
                value={validation.values.name}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                name="name"
                type="text"
                invalid={
                  validation.errors.name && validation.touched.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  <div>{validation.errors.name}</div>
                </FormFeedback>
              ) : null}
            </div>
            <Label className="my-2">Cuisine Logo</Label>
            {validation.values.image ? (
              <div>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={URL.createObjectURL(validation.values.image)}
                    width={"20%"}
                    alt=""
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>{validation.values.image.name}</div>
                  <Button
                    color="danger"
                    className="mt-2"
                    onClick={() => validation.setFieldValue("image", null)}
                    disabled={loader}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            ) : (
              <div
                {...getRootProps({
                  className: `dropzone ${isDragAccept && "isAccepted"} ${
                    isDragReject && "isRejected"
                  }`,
                })}
              >
                <input {...getInputProps()} />
                <div className="text-center upload-content">
                  <div className="upload-icon mdi mdi-plus-circle-outline"></div>
                  <div className="upload-text">Upload Logo</div>
                </div>
              </div>
            )}
            <Input
              type="hidden"
              invalid={validation.errors.image ? true : false}
            />
            {validation.errors.image && (
              <FormFeedback type="invalid">
                <div>{validation.errors.image}</div>
              </FormFeedback>
            )}

            <div className=" mb-md-0 mb-2">
              <Label>Status</Label>
              <select
                className={`form-control ${
                  validation.touched.status && validation.errors.status
                    ? "is-invalid"
                    : ""
                }`}
                name="status"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.status}
              >
                <option value={""}>Select a status</option>
                <option value={"true"}>Active</option>
                <option value={"false"}>Inactive</option>
              </select>
              {validation.touched.status && validation.errors.status ? (
                <FormFeedback type="invalid">
                  {validation.errors.status}
                </FormFeedback>
              ) : null}
            </div>
            <Button
              className="mt-2 w-100"
              color="success"
              type="submit"
              disabled={loader}
            >
              {loader ? <Spinner /> : "Save"}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddCuisine;
