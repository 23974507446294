
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/theme.scss";
import AppRoutes from "./routes";
import AppContextProvider from "./context/AppContextProvider";
import AuthContextProvider from "./context/AuthContextProvider";



createRoot(document.getElementById("root")).render(
  <AppContextProvider>
    <BrowserRouter>
      <AuthContextProvider>
        <AppRoutes />
      </AuthContextProvider>
    </BrowserRouter>
  </AppContextProvider>
);
