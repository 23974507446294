import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import * as Yup from "yup";
import userImage from "../../assets/images/users/avatar-1.jpg";
import { useAuthContext } from "../../context/AuthContextProvider";
import userService from "../../services/user.service";
import FileHistory from "../common/FileHistory";

const UserProfile = () => {
  const { userDetails, setToken, rememberMe } = useAuthContext();
  const validations = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter First Name"),
      lastName: Yup.string().required("Please enter Last Name"),
      email: Yup.string().required("Please enter Email ID"),
    }),
    onSubmit: async (values) => {
      try {
        const resp = await userService.updateUserDetails({
          ...values,
          rememberMe,
        });
        setToken(resp.data.token);
        toast.success("Details Updated Successfully");
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
      }
    },
  });

  const isFormChanged = () => {
    if (userDetails.firstName) {
      return (
        validations.values.firstName !== userDetails.firstName ||
        validations.values.lastName !== userDetails.lastName ||
        validations.values.email !== userDetails.email
      );
    } else return false;
  };

  const passwordValidation = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },

    validationSchema: Yup.object({
      password: Yup.string().required("Password required"),
      confirmPassword: Yup.string()
        .required("Field cannot be empty")
        .oneOf([Yup.ref("password"), null], "Password should match"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      setPasswordModal(!passwordModal);
    },
  });

  useEffect(() => {
    validations.setValues(userDetails);
  }, [userDetails]);

  const [passwordModal, setPasswordModal] = useState(false);
  const toggle = () => {
    setPasswordModal(!passwordModal);
    passwordValidation.resetForm();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <FileHistory title="Dashboard" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={userImage}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>
                          {userDetails.firstName} {userDetails.lastName}
                        </h5>
                        <p className="mb-1">{userDetails.email}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <h4>User details</h4>
          <Card>
            <CardBody>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validations.handleSubmit();
                    return false;
                  }}
                >
                  <div className="mb-4">
                    <Label className="form-label">First Name</Label>
                    <Input
                      name="firstName"
                      onChange={validations.handleChange}
                      onBlur={validations.handleBlur}
                      value={validations.values.firstName}
                      invalid={
                        validations.touched.firstName &&
                        validations.errors.firstName
                          ? true
                          : false
                      }
                    />
                    {validations.touched.firstName &&
                    validations.errors.firstName ? (
                      <FormFeedback type="invalid">
                        <div>{validations.errors.firstName}</div>
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-4">
                    <Label className="form-label">Last Name</Label>
                    <Input
                      name="lastName"
                      onChange={validations.handleChange}
                      onBlur={validations.handleBlur}
                      value={validations.values.lastName}
                      invalid={
                        validations.touched.lastName &&
                        validations.errors.lastName
                          ? true
                          : false
                      }
                    />
                    {validations.touched.lastName &&
                    validations.errors.lastName ? (
                      <FormFeedback type="invalid">
                        <div>{validations.errors.lastName}</div>
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-4">
                    <Label className="form-label">Email ID</Label>
                    <Input
                      name="email"
                      onChange={validations.handleChange}
                      onBlur={validations.handleBlur}
                      value={validations.values.email}
                      type="email"
                      invalid={
                        validations.touched.email && validations.errors.email
                          ? true
                          : false
                      }
                    />
                    {validations.touched.email && validations.errors.email ? (
                      <FormFeedback type="invalid">
                        <div>{validations.errors.email}</div>
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mt-4 d-flex gap-3">
                    <Button
                      type="submit"
                      color="success"
                      disabled={!isFormChanged()}
                    >
                      Update
                    </Button>
                    <Button type="button" onClick={toggle} color="danger">
                      Change Password
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </CardBody>
          </Card>
        </Container>
      </div>

      <Modal isOpen={passwordModal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle}>Change User Password</ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              passwordValidation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-4">
              <Label className="form-label">Password</Label>
              <Input
                value={passwordValidation.values.password}
                onBlur={passwordValidation.handleBlur}
                name="password"
                onChange={passwordValidation.handleChange}
                type="password"
                className="form-control"
                invalid={
                  passwordValidation.touched.password &&
                  passwordValidation.errors.password
                    ? true
                    : false
                }
              />
              {passwordValidation.touched.password &&
              passwordValidation.errors.password ? (
                <FormFeedback type="invalid">
                  <div>{passwordValidation.errors.password}</div>
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-4">
              <Label className="form-label">Retype Password</Label>
              <Input
                value={passwordValidation.values.confirmPassword}
                onBlur={passwordValidation.handleBlur}
                name="confirmPassword"
                onChange={passwordValidation.handleChange}
                type="password"
                className="form-control"
                invalid={
                  passwordValidation.touched.confirmPassword &&
                  passwordValidation.errors.confirmPassword
                    ? true
                    : false
                }
              />
              {passwordValidation.touched.confirmPassword &&
              passwordValidation.errors.confirmPassword ? (
                <FormFeedback type="invalid">
                  <div>{passwordValidation.errors.confirmPassword}</div>
                </FormFeedback>
              ) : null}
            </div>
            <Button color="success" type="submit">
              Save
            </Button>
          </Form>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  );
};

export default UserProfile;
