import http from "../api/config";
import Api from "../constants/Api";

const loginUser = async (data) => {
  try {
    const res = await http.post(Api.Auth.login, data);
    localStorage.setItem("authToken", res.data.accessToken);
    return { success: true, message: res.message };
  } catch (error) {
    console.error(error);
    return { success: false, message: error.message };
  }
};

const authService = { loginUser };

export default authService;
