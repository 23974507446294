/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContextProvider";

const ProtectedRoutes = ({ children }) => {
  const navigate = useNavigate();

  const { setToken } = useAuthContext();
  useEffect(() => {
    document.body.setAttribute("data-bs-theme", "dark");
    document.body.setAttribute("data-sidebar", "dark");

    return () => {
      document.body.removeAttribute("data-bs-theme");
      document.body.removeAttribute("data-sidebar");
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setToken(token);
    } else navigate("/login");
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

export default ProtectedRoutes;
