import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useRef, useState } from "react";
import { Input, InputGroup } from "reactstrap";
import envConfig from "../../utils/envConfig";

// Declare the libraries array outside the component
const libraries = ["places"];

const initialCenter = {
  lat: 37.7749,
  lng: -122.4194,
};

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const GoogleMapsLocation = ({ validation }) => {
  const [mapCenter, setMapCenter] = useState(initialCenter);

  const mapRef = useRef(null);
  const autocompleteRef = useRef(null);

  const handlePlaceSelect = () => {
    console.log("Hello");
    const place = autocompleteRef.current.getPlace();
    if (place && place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setMapCenter({ lat, lng });

      validation.setFieldValue("lat", lat);
      validation.setFieldValue("lng", lng);

      if (place.geometry.viewport) {
        mapRef.current.fitBounds(place.geometry.viewport);
      } else {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(10);
      }
    }
  };

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    validation.setFieldValue("lat", lat);
    validation.setFieldValue("lng", lng);
  };



  return (
    <div className="w-100">
      <InputGroup className="w-100 mb-2">
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={handlePlaceSelect}
        >
          <Input placeholder="Search for a location..." />
        </Autocomplete>
      </InputGroup>

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={mapCenter}
        zoom={10}
        onClick={handleMapClick}
        onLoad={(map) => (mapRef.current = map)}
      >
        {validation.values.lat && validation.values.lng && (
          <Marker
            position={{
              lat: validation.values.lat,
              lng: validation.values.lng,
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default GoogleMapsLocation;
