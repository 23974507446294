import React from "react";
import { Button, Form, FormFeedback, Input, Label } from "reactstrap";
import GoogleMapsLocation from "../../location/GoogleMaps";

const LoginDetails = ({ validation }) => {
  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <div>
          <Label>Password</Label>
          <Input
            name="password"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            type="password"
            invalid={
              validation.touched.password && validation.errors.password
                ? true
                : false
            }
          />
          {validation.touched.password && validation.errors.password ? (
            <FormFeedback type="invalid">
              <div>{validation.errors.password}</div>
            </FormFeedback>
          ) : null}
        </div>
        <div className="mb-3">
          <Label>Commission (%)</Label>
          <Input
            name="commission"
            value={validation.values.commission}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.commission && validation.errors.commission
                ? true
                : false
            }
          />
          {validation.touched.commission && validation.errors.commission ? (
            <FormFeedback type="invalid">
              {validation.errors.commission}
            </FormFeedback>
          ) : null}
        </div>
        <Label>Select your location</Label>
        <Input type="hidden" invalid={validation.errors.lat ? true : false} />

        {validation.errors.lat && (
          <FormFeedback color="danger">Location is required</FormFeedback>
        )}
        <GoogleMapsLocation validation={validation} />
        <Button color="success" className="mt-4 w-100" type="submit">
          Save
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default LoginDetails;
