import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { toast } from "react-toastify";
import cuisineService from "../../../services/cuisine.service";

const StatusToggle = ({ id, merchant_id, status, list }) => {
  const [statusToggle, setStatusToggle] = useState(status);

  const updateStatus = async () => {
    setStatusToggle(!statusToggle);
    const resp = await cuisineService.updateCuisineStatus(id, !statusToggle);
    if (!resp.success) {
      toast.error("Error updating Status ");
    }
  };

  useEffect(() => {
    setStatusToggle(status);
  }, [status]);

  return (
    <FormGroup switch>
      <Input
        type="switch"
        role="switch"
        checked={statusToggle}
        onChange={updateStatus}
      />
    </FormGroup>
  );
};

export default StatusToggle;
