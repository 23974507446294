import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import useDidUpdate from "../../../hooks/useDidUpdate";
import restaurantService from "../../../services/restaurant.service";
import Schedule from "../Schedule";
import ScheduleDisplay from "../ScheduleDisplay";

const Settings = ({ delivery, pickup }) => {
  const [deliveryCheck, setDeliveryCheck] = useState(delivery);
  const [pickupCheck, setPickupCheck] = useState(pickup);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  useDidUpdate(() => {
    const updatePickup = async () => {
      const resp = await restaurantService.updatePickup(pickupCheck);
      if (resp.success) {
        toast.success(resp.message);
      } else {
        toast.error(resp.message);
      }
    };
    updatePickup();
  }, [pickupCheck]);

  useDidUpdate(() => {
    const updateDelivery = async () => {
      const resp = await restaurantService.updateDelivery(pickupCheck);
      if (resp.success) {
        toast.success(resp.message);
      } else {
        toast.error(resp.message);
      }
    };
    updateDelivery();
  }, [deliveryCheck]);

  return (
    <React.Fragment>
      <div className="d-flex gap-2">
        <div>
          <FormGroup switch>
            <Input
              type="switch"
              role="switch"
              style={{ width: "35px", height: "15px" }}
              checked={deliveryCheck}
              onChange={() => setDeliveryCheck(!deliveryCheck)}
            />
            <Label check className="ms-2">
              Delivery
            </Label>
          </FormGroup>
        </div>
        <div>
          <FormGroup switch>
            <Input
              type="switch"
              role="switch"
              style={{ width: "35px", height: "15px" }}
              checked={pickupCheck}
              onChange={() => setPickupCheck(!pickupCheck)}
            />
            <Label check className="ms-2">
              Pickup
            </Label>
          </FormGroup>
        </div>
      </div>

      <div className="mt-3 d-flex flex-column">
        <Label>Schedule</Label>
        <div className="my-2">No Schedule Added</div>
        {/* <ScheduleDisplay /> */}
        <Schedule />
        <Button onClick={toggle}>Set Schedule</Button>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}> Add Schedule</ModalHeader>
        <ModalBody>
          <Schedule />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Settings;
