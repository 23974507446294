import React, { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useAuthContext } from "../../context/AuthContextProvider";
import userService from "../../services/user.service";

const Layout = (props) => {
  const { token, setUserDetails } = useAuthContext();
  useEffect(() => {
    const fetchData = async () => {
      const resp = await userService.getUserDetails();
      setUserDetails(resp.data);
    };
    fetchData();
  }, [token]);
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <Sidebar theme={"dark"} />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Layout;
