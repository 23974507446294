import React from "react";

const My404Component = () => {
  return (
    <div className="d-flex justify-content-center align-items-center " style={{ height: "100vh" }}>
      <h4>404 Route not found</h4>
    </div>
  );
};

export default My404Component;
