import React from "react";
import { useAppContext } from "../../context/AppContextProvider";
import { useAuthContext } from "../../context/AuthContextProvider";

const Dashboard = () => {
  const { test } = useAppContext();
  const { logout } = useAuthContext();

  return (
    <div className="page-content">
      <h1>Dashboard Page</h1>
      <h1>{test}</h1>
      <button onClick={logout}>Logout</button>
    </div>
  );
};

export default Dashboard;
