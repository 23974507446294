import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import addressService from "../../../services/address.service";

const RestaurantDetails = ({ restaurantDetailsValidation }) => {
  const [stateList, setStateList] = useState([]);
  const [neighborhoodList, setNeighborhoodList] = useState([]);
  const [zoneList, setZoneList] = useState([]);

  const onLogoDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      restaurantDetailsValidation.setFieldValue("logoFile", acceptedFiles[0]);
    }
  };

  const onBackgroundDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      restaurantDetailsValidation.setFieldValue(
        "backgroundImage",
        acceptedFiles[0]
      );
    }
  };
  const {
    getRootProps: logoRootProps,
    getInputProps: logoInputProps,
    isDragAccept: logoDragAccepted,
    isDragReject: logoDragReject,
  } = useDropzone({
    onDrop: onLogoDrop,
    accept: { "image/*": [] },
  });
  const {
    getRootProps: backgroundImageRootProps,
    getInputProps: backgroundImageInputProps,
    isDragAccept: backgroundDragAccepted,
    isDragReject: backgroundDragReject,
  } = useDropzone({
    onDrop: onBackgroundDrop,
    accept: { "image/*": [] },
  });

  useEffect(() => {
    const getStateList = async () => {
      const res = await addressService.getStateList();
      res.success && setStateList(res.data);
      !res.success && toast.error("Error fetching state list");
    };
    getStateList();
  }, []);

  const deleteLogoImage = () => {
    restaurantDetailsValidation.setFieldValue("logoFile", null);
  };
  const deleteBackgroundImage = () => {
    restaurantDetailsValidation.setFieldValue("backgroundImage", null);
  };

  useEffect(() => {
    if (restaurantDetailsValidation.values.state) {
      const getZoneList = async () => {
        const res = await addressService.getZoneList(
          restaurantDetailsValidation.values.state
        );
        res.success && setZoneList(res.data);
        !res.success && toast.error("Error fetching state list");
      };
      getZoneList();
    } else {
      setZoneList([]);
    }
  }, [restaurantDetailsValidation.values.state]);
  const getNeighborhoodList = async (zoneId) => {
    const res = await addressService.getNeighborhoodList(zoneId);
    res.success && setNeighborhoodList(res.data);
    !res.success && toast.error("Error fetching Neighborhood list");
  };
  useEffect(() => {
    const getStateList = async () => {
      const res = await addressService.getStateList();
      res.success && setStateList(res.data);
      !res.success && toast.error("Error fetching state list");
    };
    getStateList();
  }, []);

  return (
    <Card body>
      <Form
        className="w-100"
        onSubmit={(e) => {
          e.preventDefault();
          restaurantDetailsValidation.handleSubmit();
          return false;
        }}
      >
        <Row id="Restaurant_Details" className="mb-3">
          <Col sm="12" md="6" lg="4">
            <div className="ms-3 mb-md-0 mb-2">
              <Label>Restaurant Name</Label>
              <Input
                name="restaurantName"
                onChange={restaurantDetailsValidation.handleChange}
                onBlur={restaurantDetailsValidation.handleBlur}
                value={restaurantDetailsValidation.values.restaurantName}
                invalid={
                  restaurantDetailsValidation.touched.restaurantName &&
                  restaurantDetailsValidation.errors.restaurantName
                    ? true
                    : false
                }
              />
              {restaurantDetailsValidation.touched.restaurantName &&
              restaurantDetailsValidation.errors.restaurantName ? (
                <FormFeedback type="invalid">
                  <div>{restaurantDetailsValidation.errors.restaurantName}</div>
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col sm="12" md="6" lg="4">
            <div className="ms-3 mb-md-0 mb-2">
              <Label>Restaurant Slug</Label>
              <Input
                name="restaurantSlug"
                onChange={restaurantDetailsValidation.handleChange}
                onBlur={restaurantDetailsValidation.handleBlur}
                value={restaurantDetailsValidation.values.restaurantSlug}
                invalid={
                  restaurantDetailsValidation.touched.restaurantSlug &&
                  restaurantDetailsValidation.errors.restaurantSlug
                    ? true
                    : false
                }
              />
              {restaurantDetailsValidation.touched.restaurantSlug &&
              restaurantDetailsValidation.errors.restaurantSlug ? (
                <FormFeedback type="invalid">
                  <div>{restaurantDetailsValidation.errors.restaurantSlug}</div>
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col sm="12" md="6" lg="4">
            <div className="ms-3 mb-md-0 mb-2">
              <Label>Restaurant Phone</Label>
              <Input
                name="restaurantPhone"
                onChange={restaurantDetailsValidation.handleChange}
                onBlur={restaurantDetailsValidation.handleBlur}
                value={restaurantDetailsValidation.values.restaurantPhone}
                invalid={
                  restaurantDetailsValidation.touched.restaurantPhone &&
                  restaurantDetailsValidation.errors.restaurantPhone
                    ? true
                    : false
                }
              />
              {restaurantDetailsValidation.touched.restaurantPhone &&
              restaurantDetailsValidation.errors.restaurantPhone ? (
                <FormFeedback type="invalid">
                  <div>
                    {restaurantDetailsValidation.errors.restaurantPhone}
                  </div>
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row id="Contact_Info" className="mb-3">
          <Col sm="12" md="6" lg="4">
            <div className="ms-3 mb-md-0 mb-2">
              <Label>Contact Name</Label>
              <Input
                name="contactName"
                onChange={restaurantDetailsValidation.handleChange}
                onBlur={restaurantDetailsValidation.handleBlur}
                value={restaurantDetailsValidation.values.contactName}
                invalid={
                  restaurantDetailsValidation.touched.contactName &&
                  restaurantDetailsValidation.errors.contactName
                    ? true
                    : false
                }
              />
              {restaurantDetailsValidation.touched.contactName &&
              restaurantDetailsValidation.errors.contactName ? (
                <FormFeedback type="invalid">
                  <div>{restaurantDetailsValidation.errors.contactName}</div>
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col sm="12" md="6" lg="4">
            <div className="ms-3 mb-md-0 mb-2">
              <Label>Contact Email</Label>
              <Input
                name="contactEmail"
                onChange={restaurantDetailsValidation.handleChange}
                onBlur={restaurantDetailsValidation.handleBlur}
                value={restaurantDetailsValidation.values.contactEmail}
                invalid={
                  restaurantDetailsValidation.touched.contactEmail &&
                  restaurantDetailsValidation.errors.contactEmail
                    ? true
                    : false
                }
                type="email"
              />
              {restaurantDetailsValidation.touched.contactEmail &&
              restaurantDetailsValidation.errors.contactEmail ? (
                <FormFeedback type="invalid">
                  <div>{restaurantDetailsValidation.errors.contactEmail}</div>
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col sm="12" md="6" lg="4">
            <div className="ms-3 mb-md-0 mb-2">
              <Label>Street</Label>
              <Input
                name="street"
                onChange={restaurantDetailsValidation.handleChange}
                onBlur={restaurantDetailsValidation.handleBlur}
                value={restaurantDetailsValidation.values.street}
                invalid={
                  restaurantDetailsValidation.touched.street &&
                  restaurantDetailsValidation.errors.street
                    ? true
                    : false
                }
              />
              {restaurantDetailsValidation.touched.street &&
              restaurantDetailsValidation.errors.street ? (
                <FormFeedback type="invalid">
                  <div>{restaurantDetailsValidation.errors.street}</div>
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row className="mb-3" id="Location_Section">
          <Col sm="12" md="6" lg="4">
            <div className="ms-3 mb-md-0 mb-2">
              <Label>State</Label>
              <select
                className={`form-control ${
                  restaurantDetailsValidation.touched.state &&
                  restaurantDetailsValidation.errors.state
                    ? "is-invalid"
                    : ""
                }`}
                name="state"
                onChange={restaurantDetailsValidation.handleChange}
                onBlur={restaurantDetailsValidation.handleBlur}
                value={restaurantDetailsValidation.values.state}
              >
                <option value={""}>Select a State</option>
                {stateList.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              {restaurantDetailsValidation.touched.state &&
              restaurantDetailsValidation.errors.state ? (
                <FormFeedback type="invalid">
                  {restaurantDetailsValidation.errors.state}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col sm="12" md="6" lg="4">
            <div className="ms-3 mb-md-0 mb-2">
              <Label>Zone</Label>
              <select
                className={`form-control ${
                  restaurantDetailsValidation.touched.zone &&
                  restaurantDetailsValidation.errors.zone
                    ? "is-invalid"
                    : ""
                }`}
                name="zone"
                onChange={(e) => {
                  restaurantDetailsValidation.handleChange(e);
                  const selectedZone = zoneList.find(
                    (zone) => zone.zone_name === e.target.value
                  );
                  if (selectedZone) {
                    getNeighborhoodList(selectedZone.zone_id);
                  }
                  if (e.target.value === "") {
                    setNeighborhoodList([]);
                    restaurantDetailsValidation.setFieldValue(
                      "neighborhood",
                      ""
                    );
                  }
                }}
                onBlur={restaurantDetailsValidation.handleBlur}
                value={restaurantDetailsValidation.values.zone}
              >
                <option value={""}>
                  {restaurantDetailsValidation.values.state &&
                    "Please Select a Zone"}
                  {!restaurantDetailsValidation.values.state &&
                    "Please Select a State"}
                </option>
                {zoneList.map((zone) => (
                  <option key={zone.zone_id} value={zone.zone_name}>
                    {zone.zone_name}
                  </option>
                ))}
              </select>
              {restaurantDetailsValidation.touched.zone &&
              restaurantDetailsValidation.errors.zone ? (
                <FormFeedback type="invalid">
                  {restaurantDetailsValidation.errors.zone}
                </FormFeedback>
              ) : null}
            </div>
          </Col>

          <Col sm="12" md="6" lg="4">
            <div className="ms-3 mb-md-0 mb-2">
              <Label>Neighborhood</Label>
              <select
                className={`form-control ${
                  restaurantDetailsValidation.touched.neighborhood &&
                  restaurantDetailsValidation.errors.neighborhood
                    ? "is-invalid"
                    : ""
                }`}
                name="neighborhood"
                onChange={restaurantDetailsValidation.handleChange}
                onBlur={restaurantDetailsValidation.handleBlur}
                value={restaurantDetailsValidation.values.neighborhood}
              >
                <option value="">
                  {restaurantDetailsValidation.values.zone &&
                    "Please Select a Neighborhood"}
                  {!restaurantDetailsValidation.values.zone &&
                    "Please Select a Zone"}
                </option>
                {neighborhoodList.map((neighborhood) => (
                  <option
                    key={neighborhood.neighborhood_id}
                    value={neighborhood.neighborhood_name}
                  >
                    {neighborhood.neighborhood_name}
                  </option>
                ))}
              </select>
              {restaurantDetailsValidation.touched.neighborhood &&
              restaurantDetailsValidation.errors.neighborhood ? (
                <FormFeedback type="invalid">
                  {restaurantDetailsValidation.errors.neighborhood}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row id="File_Upload">
          <Col sm="6">
            {!restaurantDetailsValidation.values.logoFile ? (
              <div
                {...logoRootProps({
                  className: `dropzone ${logoDragAccepted && "isAccepted"} ${
                    logoDragReject && "isRejected"
                  }`,
                })}
              >
                <input {...logoInputProps()} />
                <div className="text-center upload-content">
                  <div className="upload-icon mdi mdi-plus-circle-outline"></div>
                  <div className="upload-text">Upload Logo</div>
                </div>
              </div>
            ) : (
              <div>
                <div className="d-flex  justify-content-center align-items-center">
                  <img
                    src={URL.createObjectURL(
                      restaurantDetailsValidation.values.logoFile
                    )}
                    className="imagePreview "
                    alt=""
                  />
                </div>
                <div className="mt-2 d-flex justify-content-between align-items-center">
                  <h5>Restaurant Logo</h5>
                  <Button
                    className="mdi mdi-trash-can"
                    color="danger"
                    onClick={deleteLogoImage}
                  ></Button>
                </div>
              </div>
            )}

            <Input
              type="hidden"
              invalid={
                restaurantDetailsValidation.errors.logoFile ? true : false
              }
            />
            {restaurantDetailsValidation.errors.logoFile && (
              <FormFeedback type="invalid">
                {restaurantDetailsValidation.errors.logoFile}
              </FormFeedback>
            )}
          </Col>
          <Col sm="6">
            {!restaurantDetailsValidation.values.backgroundImage ? (
              <div
                {...backgroundImageRootProps({
                  className: `dropzone ${
                    backgroundDragAccepted && "isAccepted"
                  } ${backgroundDragReject && "isRejected"}`,
                })}
              >
                <input {...backgroundImageInputProps()} />
                <div className="text-center upload-content">
                  <div className="upload-icon mdi mdi-plus-circle-outline"></div>
                  <div className="upload-text">Upload Background Image</div>
                </div>
              </div>
            ) : (
              <div>
                <div className="d-flex  justify-content-center align-items-center">
                  <img
                    src={URL.createObjectURL(
                      restaurantDetailsValidation.values.backgroundImage
                    )}
                    className="imagePreview "
                    alt=""
                  />
                </div>
                <div className="mt-2 d-flex justify-content-between align-items-center">
                  <h5>Restaurant Background Image</h5>
                  <Button
                    className="mdi mdi-trash-can"
                    color="danger"
                    onClick={deleteBackgroundImage}
                  ></Button>
                </div>
              </div>
            )}
            <Input
              type="hidden"
              invalid={
                restaurantDetailsValidation.errors.backgroundImage
                  ? true
                  : false
              }
            />
            {restaurantDetailsValidation.errors.backgroundImage && (
              <FormFeedback type="invalid">
                {restaurantDetailsValidation.errors.backgroundImage}
              </FormFeedback>
            )}
          </Col>
        </Row>
        <Button color="success" className="w-100 mt-3" type="submit">
          Next
        </Button>
      </Form>
    </Card>
  );
};

export default RestaurantDetails;
