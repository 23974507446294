import userApi from "../api/userApi";
import Api from "../constants/Api";

const getUserDetails = async () => {
  try {
    const resp = await userApi.get(Api.User.getDetails);
    return { success: true, data: resp.data };
  } catch (error) {
    return { success: false, data: error };
  }
};

const updateUserDetails = async (data) => {
  try {
    const resp = await userApi.put(Api.User.updateDetails, data);
    return { success: true, data: resp.data, message: resp.message };
  } catch (error) {
    return { success: false, data: error, message: error.message };
  }
};
const updateUserPassword = async (data) => {
  try {
    const resp = await userApi.put(Api.User.updatePassword, data);
    return { success: true, data: resp.data, message: resp.message };
  } catch (error) {
    return { success: false, data: error, message: error.message };
  }
};


const userService = { getUserDetails, updateUserDetails, updateUserPassword };
export default userService;
