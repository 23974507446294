import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import useDidUpdate from "../../../hooks/useDidUpdate";
import cuisineService from "../../../services/cuisine.service";
import Loader from "../../common/Loader";
import StatusToggle from "./StatusToggle";

const ListCuisine = () => {
  const navigate = useNavigate();
  const [cuisineList, setCuisineList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchPageNumber, setSearchPageNumber] = useState(1);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [totalNumber, setTotalNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [loader, setLoader] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filter, setFilter] = useState("active");
  const [empty, setEmpty] = useState(false);

  const getCuisines = async () => {
    setSearchEnabled(false);
    const resp = await cuisineService.getCusisineList(pageNumber);
    if (resp.success) {
      if (resp.data.data.res.length === 0) {
        setEmpty(true);
        setTotalNumber(0);
      } else {
        setEmpty(false);
        setTotalNumber(resp.data.data.total);
        setCuisineList(resp.data.data.res);
      }
    } else {
      toast.error("Error fetching Cuisine!!");
    }
  };
  const getInactiveCuisines = async () => {
    setSearchEnabled(false);
    const resp = await cuisineService.getInactiveCusisineList(pageNumber);
    console.log(resp);
    if (resp.success) {
      if (resp.data.data.res.length === 0) {
        setEmpty(true);
        setTotalNumber(0);
      } else {
        setEmpty(false);
        setTotalNumber(resp.data.data.total);
        setCuisineList(resp.data.data.res);
      }
    } else {
      toast.error("Error fetching Cuisine!!");
    }
  };
  const getActiveCuisines = async () => {
    setSearchEnabled(false);
    const resp = await cuisineService.getActiveCusisineList(pageNumber);
    if (resp.success) {
      if (resp.data.data.res.length === 0) {
        setEmpty(true);
        setTotalNumber(0);
      } else {
        setEmpty(false);
        setTotalNumber(resp.data.data.total);
        setCuisineList(resp.data.data.res);
      }
    } else {
      toast.error("Error fetching Cuisine!!");
    }
  };
  useEffect(() => {
    switch (filter) {
      case "active":
        getActiveCuisines();
        break;
      case "inactive":
        getInactiveCuisines();
        break;
      default:
        getCuisines();
        break;
    }
  }, [pageNumber]);

  useDidUpdate(() => {
    searchResult(searchTerm);
  }, [searchPageNumber]);
  const nextPage = () => {
    if (searchEnabled) {
      if (searchPageNumber < Math.ceil(totalNumber / 10)) {
        setSearchPageNumber(searchPageNumber + 1);
      }
    } else {
      if (pageNumber < Math.ceil(totalNumber / 10)) {
        setPageNumber(pageNumber + 1);
      }
    }
  };
  const prevPage = () => {
    if (searchEnabled) {
      if (searchPageNumber > 1) {
        setSearchPageNumber(searchPageNumber - 1);
      }
    } else {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1);
      }
    }
  };

  const searchResult = async (searchTerm) => {
    const res = await cuisineService.searchCuisine(
      searchTerm,
      searchPageNumber
    );
    if (res.success) {
      if (res.data.data.data.res.length === 0) {
        setEmpty(true);
        setTotalNumber(0);
      } else {
        setEmpty(false);
        setSearchEnabled(true);
        setLoader(false);
        setCuisineList(res.data.data.data.res);
        setTotalNumber(res.data.data.data.total);
      }
    } else {
      toast.error("Error fetching Cuisines!!");
      setLoader(false);
      getCuisines();
    }
  };
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value === "") {
      getCuisines();
    }
  };
  const deleteCuisine = async (id) => {
    const resp = await cuisineService.deleteCuisine(id);
    if (resp.success) {
      toast.success(resp.data.message);
      getCuisines();
    } else {
      toast.error(resp.data.message);
    }
  };
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  if (cuisineList.length == 0 || loader) {
    console.log("Hello");
    return (
      <div className="page-content">
        <Loader />
      </div>
    );
  }

  return (
    <div className="page-content">
      <div className="d-flex justify-content-end align-items-center">
        <InputGroup>
          <Input
            type="text"
            placeholder="Search Restaurant"
            onChange={handleSearchChange}
            value={searchTerm}
            onKeyDown={(e) => {
              e.key === "Enter" && searchResult(e.target.value);
            }}
          />

          <Button color="primary" onClick={() => searchResult(searchTerm)}>
            Search
          </Button>
        </InputGroup>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle caret className="mx-2">
            <i className="mdi mdi-filter"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                getActiveCuisines();
              }}
            >
              Active
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                getInactiveCuisines();
              }}
            >
              Inactive
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                getCuisines();
              }}
            >
              All
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="my-2">{totalNumber} results found</div>
      {!empty && (
        <div className="table-responsive table-card mt-3 mb-1">
          <table className="table align-middle table-nowrap" id="customerTable">
            <thead className="table-light">
              <tr>
                <th scope="col" style={{ width: "20px" }}>
                  #ID
                </th>
                <th className="sort">Cuisine Image</th>
                <th className="sort" data-sort="email">
                  Cuisine Name
                </th>
                <th className="sort" data-sort="phone">
                  Status
                </th>
                <th className="sort" data-sort="action">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="list form-check-all">
              {cuisineList.map((cuisine) => {
                return (
                  <tr>
                    <td className="id">{cuisine.id}</td>
                    <td className="cuisine_name">
                      <img
                        src={cuisine.image}
                        height={"120px"}
                        width={"120px"}
                        alt=""
                      />
                    </td>
                    <td className="name">{cuisine.name}</td>
                    <td className="status">
                      <StatusToggle id={cuisine.id} status={cuisine.status} />
                    </td>

                    <td>
                      <div className="d-flex gap-2">
                        {/* <div className="edit">
                          <button
                            onClick={() => {
                              navigate(`/cuisine/view/${cuisine.id}`);
                            }}
                          >
                            <i className="mdi mdi-pencil" />
                          </button>
                        </div> */}
                        <div className="remove">
                          <button
                            className="btn btn-sm btn-danger remove-item-btn"
                            onClick={() => {
                              deleteCuisine(cuisine.id);
                            }}
                          >
                            <i className="mdi mdi-trash-can-outline" />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <div>Page: {searchEnabled ? searchPageNumber : pageNumber}</div>
          <div>Total Pages: {Math.ceil(totalNumber / 10)}</div>
        </div>
        <Pagination>
          <PaginationItem>
            <PaginationLink previous onClick={prevPage} />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink next onClick={nextPage} />
          </PaginationItem>
        </Pagination>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ListCuisine;
