import userApi from "../api/userApi";
import Api from "../constants/Api";



const getCuisineList = async () => {
  try {
    const cuisineList = await userApi.get(Api.Cuisine.getCusisineList);
    return { success: true, data: cuisineList.data };
  } catch (error) {
    return { success: false, data: error };
  }
};

const utilService = { getCuisineList };

export default utilService;
