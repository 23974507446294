import axios from "axios";
import envConfig from "../utils/envConfig";


// export const baseURL="https://yobobackend.sqaby.org/api"
export const baseURL = envConfig.backendUrl;

const api = axios.create({
  baseURL: baseURL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

const http = {
  get: async (url, params = {}) => {
    try {
      const res = await api.get(url, { params });
      return res.data;
    } catch (error) {
      console.error("GET request error: ", error);
      throw error.response.data;
    }
  },

  post: async (url, data) => {
    try {
      const res = await api.post(url, data);
      return res.data;
    } catch (error) {
      console.error("POST request error: ", error);
      throw error.response.data;
    }
  },
};

export default http;
